<template>
  <v-container fluid>
    <v-card tile>

      <v-card-title>
        My Payments
        <v-spacer></v-spacer>
<!--        <v-text-field-->
<!--          dense-->
<!--          v-model="search"-->
<!--          append-icon="mdi-magnify"-->
<!--          label="Search"-->
<!--          single-line-->
<!--          hide-details-->
<!--        ></v-text-field>-->
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="payments"
        :search="search"
        :loading="loading"
        loading-text="Loading... Please wait"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      payments: [],
      loading: true,
      search: '',
      headers: [
        { text: 'DATE', value: 'date' },
        { text: 'AMOUNT', value: '_amount' },
        { text: 'ACCOUNT NUMBER', value: 'accountNumber' },
        { text: 'BANK CODE', value: 'bankCode' },
        { text: 'VARIABLE SYMBOL', value: 'variableSymbol' },
        { text: 'CONSTANT SYMBOL', value: 'constantSymbol' },
        { text: 'MESSAGE FOR RECIPIENT', value: 'messageForRecipient' },
        { text: 'NOTE', value: 'note' }
      ]
    }
  },

  methods: {
    async fetchUserPayments () {
      this.payments = await this.$store.dispatch('account/fetchUserPayments')

      // Create currency formatter.
      const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'CZK' })

      for (let i = 0; i < this.payments.length; i++) {
        this.payments[i]._amount = formatter.format(this.payments[i].amount)
      }
    }
  },

  async mounted () {
    document.title = 'Payments | Masarka Student Club'
    this.loading = true
    await this.fetchUserPayments()
    this.loading = false
  }
}
</script>

<style scoped>

</style>
